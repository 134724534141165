import axios from 'axios'
import hapusToken from '@/helper/hapusLocalStore.js'

export const jabatanMixin = {
    mixin: [hapusToken],
    data () {
        return {
            detailMixinJabatan: [],
            detailMixinPangkat: [],
        }
    },
    methods: {
        detailJabatan (id) {
            axios.get("/api/data-induk/kepegawaian/jabatan/" + id).then(response => {
                this.detailMixinJabatan =  response.data.data
                this.form.fill(this.detailMixinJabatan)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapusToken mixin
                    this.clearAll()
                }
            })
        },
        detailPangkat (id) {
            axios.get("/api/data-induk/kepegawaian/pangkat/" + id).then(response => {
                this.detailMixinPangkat =  response.data.data
                this.form.fill(this.detailMixinPangkat)
            }).catch(error => {
                if (error.response.status === 401) {
                    // hapusToken mixin
                    this.clearAll()
                }
            })
        }
    }
}

export default jabatanMixin