import axios from 'axios'
// import dateFormat from '@/helper/dateFormat.js'
import hapusStorage from '@/helper/hapusLocalStore'
import dateFormat from '@/helper/dateFormat'

export const pegawaiApi = {
    mixins: [hapusStorage, dateFormat],
    data () {
        return {
            detailPegawaiMixin: []
        }
    },
    methods: {
        detailPegawai (id) {
            axios.get("/api/data-induk/kepegawaian/pegawai/" + id).then(response => {
                this.detailPegawaiMixin = response.data.data
                console.log(this.detailPegawaiMixin)
                this.form.fill(this.detailPegawaiMixin)
                this.wilayah.provinsi = this.detailPegawaiMixin.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                this.$store.dispatch("WilayahIndonesia/getKabkota", this.wilayah.provinsi)
                this.wilayah.kabkota = this.detailPegawaiMixin.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                this.$store.dispatch("WilayahIndonesia/getKecamatan", this.wilayah.kabkota)
                this.wilayah.kecamatan = this.detailPegawaiMixin.wilayah_kelurahan.wilayah_kecamatan_id
                this.$store.dispatch("WilayahIndonesia/getKelurahan", this.wilayah.kecamatan)
                this.form.wilayah_kelurahan_id = parseInt(this.form.wilayah_kelurahan_id)
                this.perangkat = this.detailPegawaiMixin.unit_pd.perangkat_pd_id
                this.$store.dispatch("Perangkat/getUnit", this.perangkat)
            }).catch(error => {
                console.log(error)
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            })
        }
    }
}

export default pegawaiApi